import React, { createContext, useContext } from 'react';

/*
 	This context provides a utility function, routeHandler(), to help you handle routing in the application
	and avoid patterns where you would have to pass down a prop through multiple levels
	of components in order to apply routing to a link.

	Per default, the Link atom component utilizes RouteContext, making it possible for you to route a
	link without having to manually pass a click handler to it.
	To override this behavior, simply pass the clickHandler prop to the Link component.

	The routeHandler utility function can also be utilized in other use cases where you might want to
	handle routing but don't want to use the Link component.

	To use the routeHandler, import useRouteContext and call it like this:
	const routeHandler = useRouteContext();
	<SomeComponent onClick={(e) => routeHandler(e, '/some/path')} />
*/

type RouteHandler = (event: React.MouseEvent, href: string, target: string) => void;

interface RouteContextProviderProps {
	children?: React.ReactNode;
	router: { push: (href: string) => void };
	mediaBasePath: string;
}

export const RouteContext = createContext(null);

export const RouteContextProvider: React.FC<RouteContextProviderProps> = (props) => {
	const { children, router, mediaBasePath } = props;

	const routeHandler: RouteHandler = (event, href, target) => {
		if (!router) return;

		const hasTargetNewWindow = event.ctrlKey === true || event.shiftKey === true || target === '_blank';

		// Use default browser behavior if the link has target="_blank" or if the user is holding down the ctrl or shift key.
		if (hasTargetNewWindow) return;

		event?.preventDefault();

		const isInternalLink =
			href.startsWith('/') || href.startsWith('#') || href.startsWith('?') || href.startsWith(window.origin);

		const isMediaLink = new URL(href, window.location.origin).pathname.startsWith(mediaBasePath);

		// Reroute if internal link. Otherwise just redirect.
		// As stated in the Next.js docs (https://nextjs.org/docs/api-reference/next/router):
		// "You don't need to use router.push for external URLs. window.location is better suited for those cases."
		if (isInternalLink && !isMediaLink) {
			router.push(href);
		} else {
			window.location.href = href;
		}
	};

	return <RouteContext.Provider value={routeHandler}>{children}</RouteContext.Provider>;
};

/**
 * @function useRouteContext
 * @returns {RouteHandler} routeHandler(event, href)
 */
export const useRouteContext = (): RouteHandler => {
	return useContext(RouteContext);
};
